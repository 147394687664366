import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';

import {
  AWS_IDENTITY_POOL_ID,
  AWS_USER_POOL_ID,
  AWS_USER_POOL_WEB_CLIENT_ID,
  BASE_DOMAIN,
  BASE_DOMAIN_REVIEW,
} from './env';

export function configureAmplify() {
  Amplify.configure({
    Auth: {
      Cognito: {
        //  Amazon Cognito User Pool ID
        userPoolId: AWS_USER_POOL_ID,
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId: AWS_USER_POOL_WEB_CLIENT_ID,
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: AWS_IDENTITY_POOL_ID,
        // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
        allowGuestAccess: true,
        // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
        // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
        signUpVerificationMethod: 'link', // 'code' | 'link'
        loginWith: {
          // OPTIONAL - Hosted UI configuration
        },
      },

      cookieStorage: {
        domain: BASE_DOMAIN_REVIEW ?? BASE_DOMAIN ?? 'pumpkin.dev', // TODO - remove in pk-3440
        expires: 365,
        path: '/',
        sameSite: 'strict',
        secure: false,
      },
    },
  });

  cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());
}
